<template>
   <div>
      <v-data-table :headers="columns" :items="filteredRecords" dense
         :items-per-page="15" :footer-props="{'items-per-page-options': [15, 30, 60]}" :hide-default-footer="!records.length"
         >
         <template v-slot:top>
            <v-toolbar flat>
               <v-text-field v-model="table.search" prepend-inner-icon="mdi-magnify" placeholder="Search" hide-details
                  class="mr-3 hidden-sm-and-down" style="max-width: 240px;" flat single-line clearable
                  />
               <v-select v-model="table.filter" :items="records.map(j => j.module).filter((value, index, self) => self.indexOf(value) === index)" placeholder="Module"
                  item-text="name"
                  :return-object="false" :menu-props="{ bottom: true, offsetY: true }" multiple hide-details clearable
                  class="mr-3 hidden-sm-and-down" style="max-width: 270px;"
                  >
                  <template v-slot:selection="{ item, index }">
                     <span v-if="index < 1">{{ index === 0 ? item : `, ${item}` }}</span>
                     <span v-if="index === 1" class="grey--text ml-2">
                        ( +{{ table.filter.length - 1 }} more )
                     </span>
                  </template>
               </v-select>
               <v-spacer />
               <v-btn x-large icon tile color="secondary" class="mx-1" @click="option.id = 0">
                  <v-icon size="44">
                     mdi-link-plus
                  </v-icon>
               </v-btn>
            </v-toolbar>
            <v-divider />
         </template>
         <template v-slot:[`item.output`]="{ item }">
            {{ outputText(item) }}
         </template>
         <!--
         <template v-slot:[`item.Logo`]="{ item }">
            <v-avatar v-if="item.Logo" size="24">
               <img :src="setUrlFromImage(item.Logo)" alt="Logo" style="object-fit: cover;" @error="() => { item.Logo = '' }">
            </v-avatar>
            <v-icon v-else size="26">
               mdi-account-circle
            </v-icon>
         </template>
         -->
         <template v-slot:[`item.actions`]="{ item }">
            <v-icon v-if="$hasRole(item.kind.toLowerCase(), 'RU')" class="ml-2" @click="modifyRecord(item)">
               {{ $hasRole(item.kind.toLowerCase(), 'U') ? 'mdi-pencil' : 'mdi-eye' }}
            </v-icon>
            <v-icon v-if="$hasRole(item.kind.toLowerCase(), 'D')" class="ml-2" @click="removeRecord(item)">
               mdi-delete
            </v-icon>
         </template>
         <template v-slot:no-data>
            <div class="my-5" style="font-size: 1.15rem;">
               No data available
            </div>
         </template>
      </v-data-table>
      <v-dialog v-model="dialog.del" max-width="330px">
         <v-card>
            <v-list-item two-line class="grey lighten-2 px-6 py-2">
               <v-list-item-content dark>
                  <v-list-item-title>{{ `Delete item ${record.name}?` }}</v-list-item-title>
                  <v-list-item-subtitle>{{ record.identity }}</v-list-item-subtitle>
               </v-list-item-content>
            </v-list-item>
            <v-card-actions class="px-6 py-4">
               <v-spacer />
               <v-btn color="grey darken-2" text class="px-4" @click="dialog.del = false">
                  Cancel
               </v-btn>
               <v-btn color="secondary" depressed class="px-4" @click="deleteRecord">
                  OK
               </v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </div>
</template>

<script>
/*
import { imageFromUrl, encrypt } from '@/utils/nirtara'
import { member } from '@/utils/fields.js'
*/
const empty = { kind: 'Integration', name: '', module: '', ipV4: '', channel: '', output: '', mode: '', username: '', password: '' }
export default {
   name: 'NIntegrationTable',

   props: {
      option: { type: Object },
      records: { type: Array },
   },

   data: () => ({
      isUsed: false,
      columns: [
         { text: 'Name', value: 'name' },
         { text: 'Module', value: 'module', width: 120 },
         { text: 'IP Address', value: 'ipV4', width: 130, align: ' pl-0' },
         { text: 'Output', value: 'output' },
         /*
         { text: 'Logo', value: 'Logo', width: 30, align: 'center pr-0', sortable: false },
         */
         { text: 'Actions', value: 'actions', width: 80, align: 'end pl-0', sortable: false },
      ],
      dialog: { del: false },
      table: { search: '', filter: [] },
      record: {},
   }),

   computed: {
      filteredRecords () {
         return this.records.filter((j) => {
            const search = this.table.search?.toLowerCase()
            var filteredName = j.name && j.name.toLowerCase().indexOf(search) !== -1
            var filteredIPv4 = j.ipV4 && j.ipV4.toLowerCase().indexOf(search) !== -1
            return (!search || filteredName || filteredIPv4) &&
                   (!this.table.filter.length || this.table.filter.includes(j.module))
         })
      },
      outputText () {
         return item => {
            return item.output ? `${this.records.find(j => j.identity === item.output).name} Channel ${item.channel}` : item.mode ? `${item.mode} Access` : 'N/A'
         }
      },
      /*
      setUrlFromImage () {
         return image => imageFromUrl(image)
      },
      */
   },
   methods: {
      modifyRecord (item) {
         Object.assign(this.option, empty, item)
         this.$vuetify.goTo(0)
      },
      removeRecord (item) {
         this.record = Object.assign({}, { id: -1 }, item)
         this.dialog.del = true
      },
      deleteRecord () {
         this.$emit('delete', this.record)
         this.dialog.del = false
      },
   },
}
</script>

<style lang="scss" scoped>
::v-deep .v-data-table-header > tr > th span {
   font-size: 1.0rem;
   letter-spacing: 0;
   min-height: 60px;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
   font-size: 0.9rem;
}
::v-deep .v-data-footer,
::v-deep .v-data-footer__select .v-select__selections .v-select__selection--comma {
   font-size: 0.9rem;
}
::v-deep .v-list-item__action:first-child {
   margin-right: 16px;
}
</style>
